@use "sass:meta" as ---kha1f6wtc2;.partner-list {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &__logo {
    line-height: 0;
    margin: 1rem;
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3847");