@use "sass:meta" as ---kha1f6wtc2;#subscribe_form {
  legend {
    small {
      margin-left: $global-margin;
    }
  }

  table {
    &.form_doska,
    &.form_hours {
      width: 100%;
    }

    &.form_doska,
    &.form_days,
    &.form_hours {
      margin-top: 0;
      margin-bottom: 0;

      th {
        padding: .5rem;
      }

      input[type='checkbox'] {
        margin-bottom: 0;
      }
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3876");