@use "sass:meta" as ---kha1f6wtc2;@use 'sass:math';

.icon {
  background-repeat: no-repeat;
  display: inline-block;

  &-add {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url('svg/icon-add-inline.svg');
    vertical-align: middle;
  }

  &-message {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url('svg/icon-message.svg');
    display: inline-block;
    vertical-align: middle;
  }

  &-status {
    display: inline-block;
    position: relative;
    margin: 0 4px;
    left: 0;
    top: 0;
    font-size: rem-calc(10);
    border: none;
    width: 35px;
    height: 14px;
  }

  &-comment { //комментарии отключены
    width: 12px;
    height: 12px;
    background-image: url('svg/icon-comment.svg');
    vertical-align: top;
  }

  &-avatar {
    width: 42px;
    height: 50px;
    background-image: url('svg/icon-avatar.svg');
  }

  &-user {
    width: 12px;
    height: 12px;
    background-image: url('svg/icon-person.svg');
  }

  &-humburger-small {
    @include hamburger($color: $light-black, $color-hover: $light-black, $width: 15px, $height: 10px, $weight: 2px);
  }

  &-m {
    &-social {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      &-vk {
        background-image: url('svg/icon-m-vk-inline.svg');
      }

      &-ok {
        background-image: url('svg/icon-m-ok-inline.svg');
      }

      &-dzen {
        background-image: url('svg/icon-m-dzen-inline.svg');
      }

      &-pinterest {
        background-image: url('svg/icon-m-pinterest-inline.svg');
      }

      &-telegram {
        background-image: url('svg/icon-m-telegram-inline.svg');
      }
    }

    &-message {
      width: 12px;
      height: 12px;
    }

    &-person {
      width: 54px;
      height: 54px;
      background-repeat: no-repeat;
      background-image: url('svg/icon-person.svg');
      display: inline-block;
    }

    &-close {
      display: inline-block;
      border: none;
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      background-image: url('svg/icon-close.svg');
    }
  }

  &-file {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    vertical-align: middle;

    &__file {
      background-image: url('svg/icon-file.svg');
    }

    &__doc {
      background-image: url('svg/icon-file-doc.svg');
    }

    &__img {
      background-image: url('svg/icon-file-img.svg');
    }

    &__pdf {
      background-image: url('svg/icon-file-pdf.svg');
    }

    &__xls {
      background-image: url('svg/icon-file-xls.svg');
    }
  }

  &-share {
    display: inline-block;
    width: 18px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url('svg/icon-share-inline.svg');
    vertical-align: middle;
  }

  &-photo {
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url('svg/icon-photo.svg');
    display: inline-block;
    vertical-align: middle;
  }

  &-views-count {
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url('svg/icon-views-count-inline.svg');
    display: inline-block;
    vertical-align: middle;
    margin-right: math.div($global-margin, 4);
  }

  &-procurement {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: .25rem;
    background-repeat: no-repeat;
    background-image: url('svg/icon-procurement-inline.svg');
    vertical-align: text-bottom;
  }

  &-gotop {
    position: fixed;
    cursor: pointer;
    bottom: 0;
    right: 5rem;
    width: 86px;
    height: 25px;
    z-index: 1001;
    background-image: url('svg/icon-gotop.svg');
  }
}

h1 .icon-stars-1,
h1 .icon-stars-2,
h1 .icon-stars-3,
h1 .icon-vip {
  position: relative;
  top: -10px;
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3835");