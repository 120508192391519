@use "sass:meta" as ---kha1f6wtc2;.company-follow-recomendations {
  margin-top: 2rem;

  .wrapper {
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    padding: .5rem;
  }

  .header {
    @extend .h3;

    font-weight: $global-weight-bold;
  }

  .button {
    margin-bottom: 0;
  }

  figure {
    margin-bottom: .5rem !important;

    img,
    svg {
      aspect-ratio: 1;
      width: 100%;
    }
  }

  figcaption {
    font-weight: $global-weight-bold;
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3880");