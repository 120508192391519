@use "sass:meta" as ---kha1f6wtc2;@use 'sass:math';

.article-block {
  display: block;
  overflow: hidden;
  position: relative;

  &__img {
    align-items: center;
    aspect-ratio: 3/2;
    border-radius: $global-radius;
    display: flex;
    line-height: 0;
    margin-bottom: math.div($global-margin, 2);
    overflow: hidden;
    justify-content: center;

    img {
      aspect-ratio: 3/2;
      object-fit: cover;
      width: 100%;
    }

    picture {
      margin: 0 auto;
    }
  }

  &__rubrica {
    @include show-for(medium);

    background-color: rgb(255 255 255 / 75%);
    border-radius: 0 0 $global-radius $global-radius;
    color: $light-black;
    font-size: rem-calc(12);
    font-weight: $global-weight-bolder;
    line-height: 1;
    padding: math.div($global-padding, 2);
    position: absolute;
    right: $global-margin;
    top: 0;
    white-space: nowrap;
  }

  &__title {
    font-weight: $global-weight-bold;
    line-height: $header-lineheight;
    margin-bottom: $header-margin-bottom;
    overflow: hidden;
    text-overflow: ellipsis;

    &:visited {
      color: $primary-color;
    }
  }

  &__date {
    display: flex;
    gap: 1rem;
    font-size: $small-font-size;
    color: $small-font-color;
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3903");