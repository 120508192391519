@use "sass:meta" as ---kha1f6wtc2;@use 'sass:math';

.video-list {
  &__date {
    font-size: $small-font-size;
    color: $small-font-color;
    margin-top: 6px;
  }

  &__thumb {
    display: block;
    line-height: 0;
    margin-bottom: math.div($global-margin, 2);
    position: relative;
    text-align: center;

    img {
      aspect-ratio: 16 / 9;
    }
  }

  &__title {
    $line-height: 1.2;

    display: block;
    height: rem-calc($global-font-size * $line-height * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: $line-height;

    @include breakpoint(medium) {
      font-weight: $global-weight-bold;
    }

    @include breakpoint(xlarge) {
      height: rem-calc($global-font-size * $line-height * 3);
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3867");