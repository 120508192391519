@use "sass:meta" as ---kha1f6wtc2;&.company-recent {
  h3 {
    font-weight: $global-weight-bold;
  }

  svg {
    height: 40px;
    width: 60px;
  }

  .title {
    font-weight: $global-weight-bolder;
    margin-bottom: .5rem;
  }

  .geo {
    font-size: $small-font-size;
    color: $small-font-color;
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3906");