@use "sass:meta" as ---kha1f6wtc2;.share-3 {
  border: 1px solid $medium-gray;
  padding: $global-padding;
  border-radius: $global-radius;
  display: block;

  @include grid-column-margin;

  @media print {
    display: none !important;
  }

  .social-likes {
    margin: .8em 0 0;
    max-width: 150px;

    &__widget {
      display: block;
      margin: 0 0 10px;
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3857");